.template-mainInstagram {
  margin-bottom: $space-xl;

  &__contents {
    display: flex;
    flex-wrap: wrap;
  }

  &__content {
    width: $eight-division;
  }

  &__link {
    text-decoration: none;
  }

  &__imageBox {
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 100%;

    & > img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

@include media_m {
  .template-mainInstagram {
    margin-bottom: $space-xl-tablet;
  }
}

@include media_s {
  .template-mainInstagram {
    &__content {
      width: $four-division;
    }
  }
}
