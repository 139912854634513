.template-mainCompany {
  margin-bottom: $space-xxl;

  &__contents {
    background-image: url("/html/template/unajo/assets/img/main_company_bg.jpg");
    position: relative;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }

  &__textArea {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    text-align: center;
    margin: 0 auto;
    padding: $space-m;
    background-color: $main-company-text-area-bg-color;

    & > img {
      display: block;
      height: auto;
      width: 150px;
      margin: 0 auto $space-s;
    }

    & > p {
      margin: 0;
      color: $white;
      font-family: $sans-serif;
      font-size: 1rem;
      font-weight: 300;

      &:nth-child(-n + 3) {
        margin-bottom: $space-xxs;
      }
    }
  }
}

@include media_l {
  .template-mainCompany {
    &__textArea {
      left: 12.5%;
    }
  }
}

@include media_m {
  .template-mainCompany {
    margin-bottom: $space-xxl-tablet;

    &__textArea {
      width: 80%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: $space-xl-tablet $space-m-tablet;

      & > img {
        width: 140px;
        margin: 0 auto $space-s-tablet;
      }

      & > p {
        font-size: 0.9rem;

        &:nth-child(-n + 3) {
          margin-bottom: $space-xxs-tablet;
        }
      }
    }
  }
}

@include media_s {
  .template-mainCompany {
    &__textArea {
      width: 90%;
      padding: $space-xl-tablet $space-s-tablet;

      & > img {
        width: 130px;
      }
    }
  }
}

@include media_xs {
  .template-mainCompany {
    &__textArea {
      width: 95%;
      padding: $space-xl-tablet $space-xs-tablet;

      & > img {
        width: 120px;
      }
    }
  }
}
