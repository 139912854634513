.template-helpPrivacy {
  &__contentTitle {
    margin-bottom: 4px;
  }

  &__list {
    list-style: inside;

    & > li {
      padding-left: 1.4em;
      text-indent: -1.4em;
    }
  }
}
