.template-wrap{
  position: relative;
  height: 100%;
  width: 95%;
  margin: 0 auto;
}

@include media_l() {
  .template-wrap {
    width: 96%;
  }
}

@include media_m() {
  .template-wrap {
    width: 97%;
  }
}

@include media_s() {
  .template-wrap {
    width: 98%;
  }
}

@include media_xs() {
  .template-wrap {
    width: 99%;
  }
}
