.template-navMenu {
  display: flex;
  margin-bottom: 0;
  list-style: none;

  &__navigationItem {
    margin-right: $space-s;

    &:last-child {
      margin-right: 0;
    }
  }

  &__itemLinkWithIcon {
    display: flex;
    align-items: center;

    & > i {
      margin-right: $space-xxs;
    }
  }

  &__itemLink {
    color: $base-text-color;
    font-family: $sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1em;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__icon {
    font-size: 1.5rem;
  }

  &__cartArea {
    position: relative;
    display: block;

    & > span {
      position: absolute;
      top: -3.75px;
      right: -3.75px;
      height: 15px;
      width: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $red;
      border-radius: 50%;
      color: $white;
      font-size: 0.8rem;
      font-weight: 300;
    }
  }
}

@include media_l {
  .template-navMenu {
    &__itemLink {
      font-size: 0.9rem;
    }

    &__icon {
      font-size: 1.4rem;
    }

    &__cartArea {
      & > span {
        top: -4.5px;
        right: -4.5px;
        height: 14px;
        width: 14px;
        font-size: 0.7rem;
      }
    }
  }
}

@include media_m {
  .template-navMenu {
    display: none;
  }
}
