.template-mainVisual {
  position: relative;
  margin-bottom: $space-xxl;

  &__imageArea {
    position: relative;
    width: $main-visual-image-width;
    margin-left: auto;
    padding-bottom: calc(#{$main-visual-image-width} * 0.65);

    & > img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__textArea {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  &__title {
    margin: 0 0 $space-m;
    color: $base-text-color;
    font-family: $serif;
    font-size: 4rem;
    font-weight: 600;
    text-shadow: 0 0 10px $white;
  }

  &__text {
    width: $main-visual-text-width;
    margin-bottom: $space-s;
    color: $base-text-color;
    font-family: $serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: $line-height-l;
  }

  &__button {
    position: relative;
    display: inline-block;
    padding: 0.75rem 2rem;
    background-color: $product-list-button-bg-color;
    border-radius: 25px;
    color: $white;
    font-family: $sans-serif;
    font-size: 1rem;
    font-weight: 600;
    overflow: hidden;
    text-decoration: none;
    transition: ease 0.2s;

    @keyframes skewAnimation {
      100% {
        left: -10%;
      }
    }

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -130%;
      background-color: $hover-product-list-button-bg-color;
      width: 120%;
      height: 100%;
      transform: skewX(-25deg);
    }

    &:hover::before {
      animation: skewAnimation 0.5s forwards;
    }

    & > span {
      position: relative;
      z-index: 3;
    }
  }
}

@include media_xl {
  .template-mainVisual {
    &__title {
      font-size: 4.5rem;
    }
  }
}

@include media_l {
  .template-mainVisual {
    &__title {
      font-size: 3.5rem;
      text-shadow: 0 0 7.5px $white;
    }

    &__imageArea {
      padding-bottom: calc(#{$main-visual-image-width} * 0.75);
    }
  }
}

@include media_m {
  .template-mainVisual {
    margin-bottom: $space-xxl-tablet;

    &__imageArea {
      width: $one-column;
      margin: 0 0 $space-m-tablet;
      padding-bottom: 50%;
    }

    &__textArea {
      position: relative;
      top: auto;
      transform: none;
    }

    &__title {
      margin: 0 0 $space-m-tablet;
      font-size: 3rem;
      text-shadow: none;
    }

    &__text {
      width: $one-column;
      margin-bottom: $space-s-tablet;
      font-size: 0.9rem;
    }

    &__button {
      font-size: 0.9rem;
    }
  }
}

@include media_s {
  .template-mainVisual {
    &__title {
      font-size: 2.5rem;
    }

    &__imageArea {
      padding-bottom: 62.5%;
    }
  }
}

@include media_xs {
  .template-mainVisual {
    &__title {
      font-size: 2rem;
    }

    &__imageArea {
      padding-bottom: 75%;
    }
  }
}
