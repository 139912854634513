.template-mainTitle {
  text-align: center;
  margin-bottom: $space-m;

  & > h2 {
    margin: 0 0 $space-xxs;
    color: $base-text-color;
    font-family: $serif;
    font-size: 3rem;
    font-weight: 600;
  }

  & > p {
    margin: 0;
    color: $base-text-color;
    font-family: $sans-serif;
    font-size: 1rem;
    font-weight: 300;
  }
}

.Topics > h2 {
  font-size: 1.8rem;
}

@include media_xl {
  .template-mainTitle {
    & > h2 {
      font-size: 3.25rem;
    }
  }

  .Topics > h2 {
    font-size: 1.875rem;
  }
}

@include media_l {
  .template-mainTitle {
    & > h2 {
      font-size: 2.75rem;
    }
  }

  .Topics > h2 {
    font-size: 1.725rem;
  }
}

@include media_m {
  .template-mainTitle {
    margin-bottom: $space-m-tablet;

    & > h2 {
      margin: 0 0 $space-xxs-tablet;
      font-size: 2.5rem;
    }

    & > p {
      font-size: 0.9rem;
    }
  }

  .Topics > h2 {
    font-size: 1.65rem;
  }
}

@include media_s {
  .template-mainTitle {
    & > h2 {
      font-size: 2.25rem;
    }
  }

  .Topics > h2 {
    font-size: 1.575rem;
  }
}

@include media_xs {
  .template-mainTitle {
    & > h2 {
      font-size: 2rem;
    }
  }

  .Topics > h2 {
    font-size: 1.5rem;
  }
}
