.template-footerContactUs {
  &__flexBox {
    display: flex;

    & > p {
      margin: 0;
      color: $white;
      font-family: $sans-serif;
      font-size: 1rem;
      font-weight: 300;

      &:first-child {
        width: 90px;
      }

      & > span {
        display: block;
        margin-bottom: $space-xxs;
      }
    }
  }
}

@include media_m {
  .template-footerContactUs {
    &__flexBox {
      & > p {
        font-size: 0.9rem;

        & > span {
          margin-bottom: $space-xxs-tablet;
        }
      }
    }
  }
}

@include media_s {
  .template-footerContactUs {
    &__flexBox {
      justify-content: center;

      & > p {
        &:first-child {
          width: auto;
        }

        & > span {
          display: inline-block;
        }
      }
    }
  }
}
