.template-footer {
  &__beforeArea {
    background-color: $footer-before-bg-color;
    padding: $space-m 0;
  }

  &__beforeContents {
    display: flex;
    justify-content: space-between;
  }

  &__mainArea {
    padding: $space-m 0 $space-xs;
    background-color: $footer-main-bg-color;
  }

  &__mainContents {
    display: flex;
    justify-content: space-between;
    margin-bottom: $space-s;
  }

  &__mainContent {
    width: calc((100% - 32.5% - 35%) - 15px * 2);
  }

  &__mainContentWide {
    width: calc((100% - 32.5% * 2) - 15px * 2);
  }

  &__copyright {
    text-align: center;
    margin: 0;
    color: $white;
    font-family: $sans-serif;
    font-size: 1rem;
    font-weight: 300;
  }
}

@include media_m {
  .template-footer {
    &__beforeArea {
      padding: $space-l-tablet 0;
    }

    &__beforeContents {
      flex-direction: column;
    }

    &__mainContent {
      width: calc((100% - 32.5% - 35%) - 10px * 2);
    }

    &__mainContentWide {
      width: calc((100% - 32.5% * 2) - 10px * 2);
    }

    &__copyright {
      font-size: 0.9rem;
    }
  }
}

@include media_s {
  .template-footer {
    &__mainContents {
      flex-direction: column;
    }

    &__mainContent {
      width: 100%;
      margin-bottom: $space-s-tablet;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__mainContentWide {
      width: 100%;
      margin-bottom: $space-s-tablet;
    }
  }
}
