.template-footerBeforeContent {
  display: flex;
  align-items: center;
  width: calc(100% / 3 - 15px * 2);

  & > img {
    width: 30px;
    margin-right: $space-s;
  }

  &__textArea {
    & > p {
      margin: 0;
      color: $white;
      font-family: $sans-serif;
      font-size: 1rem;
      font-weight: 300;

      & > strong {
        font-weight: 600;
      }
    }
  }
}

@include media_l {
  .template-footerBeforeContent {
    width: calc(100% / 3 - 10px * 2);

    & > img {
      width: 25px;
    }
  }
}

@include media_m {
  .template-footerBeforeContent {
    flex-direction: column;
    width: 100%;
    margin-bottom: $space-m-tablet;

    &:last-child {
      margin-bottom: 0;
    }

    & > img {
      width: 30px;
      margin: 0 0 $space-xs-tablet;
    }

    &__textArea {
      text-align: center;

      & > p {
        font-size: 0.9rem;
        margin-bottom: $space-xxs-tablet;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@include media_s {
  .template-footerBeforeContent {
    & > img {
      width: 27.5px;
    }
  }
}

@include media_xs {
  .template-footerBeforeContent {
    & > img {
      width: 25px;
    }
  }
}
