.template-backgroundImage {
  height: $main-bg-image-height;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@include media_l {
  .template-backgroundImage {
    height: calc(#{$main-bg-image-height} - 50px);
  }
}

@include media_m {
  .template-backgroundImage {
    height: calc(#{$main-bg-image-height} - 100px);
    background-attachment: scroll;
  }
}

@include media_s {
  .template-backgroundImage {
    height: calc(#{$main-bg-image-height} - 150px);
  }
}

@include media_xs {
  .template-backgroundImage {
    height: calc(#{$main-bg-image-height} - 200px);
  }
}
