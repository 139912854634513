.template-mainConcept {
  position: relative;
  margin-bottom: $space-xxl;
  background-image: url("/html/template/unajo/assets/img/main_concept_bg.jpg");

  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &__textArea {
    position: absolute;
    top: 20%;
    width: 100%;
  }

  &__textContents {
    margin-left: auto;
    padding: $space-m;
    width: 30%;
    background-color: $white;

    & > h3 {
      margin: 0 0 $space-xs;
      color: $base-text-color;
      font-family: $sans-serif;
      font-size: 1.1rem;
      font-weight: 600;
    }

    & > p {
      margin: 0;
      color: $base-text-color;
      font-family: $serif;
      font-size: 1rem;
      font-weight: 300;
      line-height: $line-height-m;
    }
  }
}

@include media_xl {
  .template-mainConcept {
    &__textContents {
      & > h3 {
        font-size: 1.2rem;
      }
    }
  }
}

@include media_l {
  .template-mainConcept {
    &__textArea {
      top: 10%;
    }

    &__textContents {
      width: 40%;
    }
  }
}

@include media_m {
  .template-mainConcept {
    margin-bottom: $space-xxl-tablet;

    &__textArea {
      top: 50%;
      transform: translateY(-50%);
    }

    &__textContents {
      margin: 0 auto;
      padding: $space-m-tablet;
      width: 80%;
      background-color: $white-translucent;

      & > h3 {
        margin: 0 0 $space-xs-tablet;
        font-size: 1rem;
      }

      & > p {
        font-size: 0.9rem;
      }
    }
  }
}

@include media_s {
  .template-mainConcept {
    &__textContents {
      width: 90%;
    }
  }
}

@include media_xs {
  .template-mainConcept {
    &__textContents {
      width: 95%;
    }
  }
}
