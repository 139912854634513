.template-footerCategory {
  &__flexBox {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__item {
    margin-bottom: $space-xxs;

    &:nth-child(odd) {
      width: $footer-category-width;
    }

    &:nth-child(even) {
      width: calc(100% - #{$footer-category-width});
    }

    & > a {
      color: $white;
      font-family: $sans-serif;
      font-size: 1rem;
      font-weight: 300;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@include media_m {
  .template-footerCategory {
    &__item {
      margin-bottom: $space-xxs-tablet;

      &:nth-child(odd) {
        width: calc(#{$footer-category-width} - 10px);
      }

      & > a {
        font-size: 0.9rem;
      }
    }
  }
}

@include media_s {
  .template-footerCategory {
    &__flexBox {
      flex-direction: row;
    }

    &__item {
      text-align: center;
      width: calc(100% / 2) !important;

      &:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }
    }
  }
}
