.template-header {
  position: fixed;
  z-index: 998;
  top: 0;
  width: 100vw;
  background-color: $header-bg-color;
  height: $header-height;

  &__flexBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__logoLink {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    & > img {
      height: auto;
      width: 70px;
    }

    & > p {
      margin: 0;
      color: $base-text-color;
      font-size: 0.7rem;
      font-weight: 300;
    }
  }
}

@include media_l {
  .template-header {
    &__logoLink {
      & > img {
        width: 65px;
      }

      & > p {
        font-size: 0.6rem;
      }
    }
  }
}

@include media_m {
  .template-header {
    height: calc(#{$header-height} - 5px);

    &__logoLink {
      & > img {
        width: 75px;
      }

      & > p {
        display: none;
      }
    }
  }
}

@include media_s {
  .template-header {
    height: calc(#{$header-height} - 10px);

    &__logoLink {
      & > img {
        width: 70px;
      }
    }
  }
}

@include media_xs {
  .template-header {
    height: calc(#{$header-height} - 15px);

    &__logoLink {
      & > img {
        width: 65px;
      }
    }
  }
}
