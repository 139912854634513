.template-mainCategory {
  margin-bottom: $space-xl;

  &__navigationList {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    list-style: none;
  }

  &__navigationItem {
    height: 100%;
    width: $two-column;
    margin-right: $space-s;

    &:nth-child(3n + 1) {
      background-color: $category-bg-color-1;
    }

    &:nth-child(3n + 2) {
      background-color: $category-bg-color-2;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(n + 3) {
      margin-top: $space-s;
    }
  }

  &__navigationLink {
    display: flex;
    text-decoration: none;

    &:hover {
      opacity: 0.7;
      text-decoration: none;
    }
  }

  &__textArea {
    width: $two-division;
    margin: 0 0 $space-s $space-s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    & > p {
      margin: 0;
      color: $white;
      font-family: $sans-serif;
      font-size: 1.25rem;
      font-weight: 300;

      & > strong {
        font-family: $oswald;
        font-size: 1.5rem;
      }
    }
  }

  &__imageBox {
    position: relative;
    height: 0;
    width: $two-division;
    padding-bottom: calc(#{$two-division} * 0.65);

    & > img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

@include media_xl {
  .template-mainCategory {
    &__textArea {
      & > p {
        font-size: 1.375rem;

        & > strong {
          font-size: 1.625rem;
        }
      }
    }
  }
}

@include media_l {
  .template-mainCategory {
    &__textArea {
      & > p {
        font-size: 1rem;

        & > strong {
          font-size: 1.25rem;
        }
      }
    }

    &__imageBox {
      padding-bottom: calc(#{$two-division} * 0.75);
    }
  }
}

@include media_m {
  .template-mainCategory {
    margin-bottom: $space-xl-tablet;

    &__navigationList {
      flex-wrap: wrap;
    }

    &__navigationItem {
      width: $two-column-tablet;
      margin-right: $space-s-tablet;

      &:nth-child(n + 3) {
        margin-top: $space-s-tablet;
      }
    }

    &__textArea {
      margin: 0 0 $space-s-tablet $space-s-tablet;

      & > p {
        font-size: 1.125rem;

        & > strong {
          font-size: 1.325rem;
        }
      }
    }

    &__imageBox {
      padding-bottom: calc(#{$two-division} * 0.65);
    }
  }
}

@include media_s {
  .template-mainCategory {
    &__navigationList {
      flex-direction: column;
    }

    &__navigationItem {
      width: $one-column;
      margin-right: 0;

      &:nth-child(2n) {
        margin-right: auto;
      }

      &:nth-child(n + 3) {
        margin-top: auto;
      }

      &:nth-child(n + 2) {
        margin-top: $space-s-tablet;
      }
    }

    &__textArea {
      & > p {
        font-size: 1.25rem;

        & > strong {
          font-size: 1.5rem;
        }
      }
    }

    &__imageBox {
      padding-bottom: calc(#{$two-division} * 0.55);
    }
  }
}

@include media_xs {
  .template-mainCategory {
    &__textArea {
      & > p {
        font-size: 1rem;

        & > strong {
          font-size: 1.25rem;
        }
      }
    }

    &__imageBox {
      padding-bottom: calc(#{$two-division} * 0.65);
    }
  }
}
