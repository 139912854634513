// margin, padding
$space-xxs: 5px;
$space-xs: 10px;
$space-s: 25px;
$space-m: 50px;
$space-l: 75px;
$space-xl: 100px;
$space-xxl: 125px;
$space-xxs-tablet: calc(#{$space-xxs} / 2);
$space-xs-tablet: calc(#{$space-xs} / 2);
$space-s-tablet: calc(#{$space-s} / 2);
$space-m-tablet: calc(#{$space-m} / 2);
$space-l-tablet: calc(#{$space-l} / 2);
$space-xl-tablet: calc(#{$space-xl} / 2);
$space-xxl-tablet: calc(#{$space-xxl} / 2);

// height
$main-bg-image-height: 600px;
$header-height: 60px;

// width
$one-column: 100%;
$two-column: calc((100% - #{$space-s}) / 2);
$two-column-tablet: calc((100% - #{$space-s-tablet}) / 2);
$two-column-tablet-s: calc((100% - #{$space-s-tablet}) / 2);
$three-column: calc((100% - #{$space-s} * 2) / 3);
$three-column-tablet: calc((100% - #{$space-s-tablet} * 2) / 3);
$two-division: calc(100% / 2);
$four-division: calc(100% / 4);
$eight-division: calc(100% / 8);
$main-category-image-width: 70%;
$main-category-text-width: calc(100% - #{$main-category-image-width});
$main-visual-image-width: 60%;
$main-visual-text-width: calc(100% - #{$main-visual-image-width} - #{$space-m});
$footer-category-width: 150px;
$footer-link-width: 220px;

// background-color, color
$base-bg-color: #f7f3f0;
$base-text-color: #333333;
$cart-button-bg-color: #de5d50;
$cart-button-text-color: #ffffff;
$category-bg-color-1: #ae1b02;
$category-bg-color-2: #517618;
$favorite-button-bg-color: #525263;
$favorite-button-text-color: #ffffff;
$footer-before-bg-color: #517618;
$footer-main-bg-color: #333333;
$header-bg-color: #fffcf9;
$product-list-button-bg-color: #c4c4c4;
$hover-product-list-button-bg-color: #333333;
$main-company-text-area-bg-color: rgba(174, 27, 2, 0.4);
$black: #000000;
$red: #c90000;
$white: #ffffff;
$white-translucent: rgba(255, 255, 255, 0.8);

// font-family
$sans-serif: "Helvetica Neue", Arial, Meiryo, "Hiragino Kaku Gothic ProN",
  "Hiragino Sans", Meiryo, sans-serif;
$serif: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝",
  "ＭＳ 明朝", serif;
$oswald: "Oswald", $sans-serif;

// line-height
$line-height-m: 1.75em;
$line-height-l: 2em;

// MediaQuery
$tablet: 480px;
$desktop: 768px;
$desktop2: 992px;
$desktop3: 1200px;

$font-size: 16px;

$clrRed: #de5d50;
$clrRich: #9a947e;
$clrGray: #f3f3f3;
$clrRightGray: #b8bec4;
$clrExtraGray: #636378;
$clrDarkGray: #525263;
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
