@import url('../../../../../node_modules/ress/ress.css');
@import "component/1.1.heading";
@import "component/1.2.typo";
@import "component/1.3.list";
@import "component/2.1.buttonsize";
@import "component/2.2.closebutton.scss";
@import "component/2.3.otherbutton";
@import "component/3.1.inputText";
@import "component/3.2.inputMisc";
@import "component/3.3.form";
@import "component/4.1.icon";
@import "component/5.1.grid";
@import "component/5.2.layout";
@import "component/6.1.login";
@import "component/7.1.itembanner";
@import "component/7.2.search";
@import "component/7.3.cart";
@import "component/8.1.info";
@import "component/8.2.banner";
@import "component/9.1.mypage";
@import "project/11.1.role";
@import "project/11.2.header";
@import "project/11.3.footer";
@import "project/12.1.slider";
@import "project/12.2.eyecatch";
@import "project/12.3.button";
@import "project/12.4.heading";
@import "project/12.5.topics";
@import "project/12.6.newItem";
@import "project/12.7.category";
@import "project/12.8.news";
@import "project/13.1.searchnav";
@import "project/13.2.shelf";
@import "project/13.3.pager";
@import "project/13.4.cartModal";
@import "project/14.1.product";
@import "project/15.1.cart";
@import "project/15.2.order";
@import "project/16.1.history";
@import "project/16.2.historyDetail";
@import "project/17.1.address";
@import "project/18.1.password";
@import "project/19.1.register";
@import "project/19.2.contact";
@import "project/19.3.customer";
@import "project/20.1.404";
@import "project/21.1.withdraw";
@import "project/22.1.editComplete";

@import "component/_1.1.heading.scss";
@import "component/_1.2.typo.scss";
@import "component/_1.3.list.scss";
@import "component/_2.1.buttonsize.scss";
@import "component/_2.2.closebutton.scss";
@import "component/_2.3.otherbutton.scss";
@import "component/_3.1.inputText.scss";
@import "component/_3.2.inputMisc.scss";
@import "component/_3.3.form.scss";
@import "component/_4.1.icon.scss";
@import "component/_5.1.grid.scss";
@import "component/_5.2.layout.scss";
@import "component/_6.1.login.scss";
@import "component/_7.1.itembanner.scss";
@import "component/_7.2.search.scss";
@import "component/_7.3.cart.scss";
@import "component/_8.1.info.scss";
@import "component/_8.2.banner.scss";
@import "component/_9.1.mypage.scss";
@import "component/background/_backgroundImage.scss";
@import "component/block/_nav_sp.scss";
@import "component/common/_footerBeforeContent.scss";
@import "component/common/_footerCategory.scss";
@import "component/common/_footerContactUs.scss";
@import "component/common/_footerLink.scss";
@import "component/frame/_maxWidth.scss";
@import "component/frame/_wrap.scss";
@import "component/holder/_mainCategory.scss";
@import "component/holder/_mainCompany.scss";
@import "component/holder/_mainConcept.scss";
@import "component/holder/_mainInstagram.scss";
@import "component/holder/_mainNewItems.scss";
@import "component/holder/_mainVisual.scss";
@import "component/layout/_footer.scss";
@import "component/layout/_header.scss";
@import "component/navigation/_navMenu.scss";
@import "component/title/_footerTitle.scss";
@import "component/title/_mainTitle.scss";
@import "page/_default_frame.scss";
@import "page/_help_privacy.scss";
@import "page/_product_detail.scss";
@import "page/_product_list.scss";

body,html{
  font-size:16px;
  font-family:$sans-serif;
}

@media only screen and (max-width:768px){
  body,html{
    font-size:15px;
  }
}

@media only screen and (max-width: 519px){
  body,html{
    font-size:14px;
  }
}
