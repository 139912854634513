.template-footerTitle {
  & > p {
    color: $white;
    font-family: $oswald;
    font-size: 1rem;
    font-weight: 600;
  }
}

@include media_l {
  .template-footerTitle {
    text-align: center;
  }
}

@include media_m {
  .template-footerTitle {
    & > p {
      font-size: 0.9rem;
    }
  }
}
