.template-productDetail {
  &__cartButton {
    background-color: $cart-button-bg-color;
    border: none;
    color: $cart-button-text-color;
  }

  &__favoriteButton {
    background-color: $favorite-button-bg-color;
    border: none;
    color: $favorite-button-text-color;
  }
}
