.template-productList {
  &__imageBox {
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 100%;

    & > img {
      position: absolute;
      height: 100%;
      width: 100% !important;
      object-fit: cover;
    }
  }

  &__cartButton {
    background-color: $cart-button-bg-color;
    border: none;
    color: $cart-button-text-color;
  }
}
