.template-defaultFrame {
  margin: 0 auto;

  &__topPage {
    background-color: $base-bg-color;
  }

  &__header {
    position: relative;
    height: $header-height;
  }

  &__main {
    padding: $space-xxl 0;
  }

  &__overlayRole {
    z-index: 999;
  }
}

@include media_m {
  .template-defaultFrame {
    &__header {
      height: calc(#{$header-height} - 5px);
    }

    &__main {
      padding: $space-xxl-tablet 0;
    }
  }
}

@include media_s {
  .template-defaultFrame {
    &__header {
      height: calc(#{$header-height} - 10px);
    }
  }
}

@include media_xs {
  .template-defaultFrame {
    &__header {
      height: calc(#{$header-height} - 15px);
    }
  }
}
