@include media_m {
  .template-navSp {
    padding: 0;
    width: auto;
    height: auto;
    font-size: 30px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0);
    top: calc(50% - (30px / 2));
    right: 0;
    left: auto;
  }
}

@include media_s {
  .template-navSp {
    font-size: 25px;
    top: calc(50% - (25px / 2));
  }
}
@include media_xs {
  .template-navSp {
    font-size: 20px;
    top: calc(50% - (20px / 2));
  }
}
