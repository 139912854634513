.template-mainNewItems {
  margin-bottom: $space-xxl;

  &__contents {
    display: flex;
    flex-wrap: wrap;
  }

  &__content {
    width: $three-column;
    margin-right: $space-s;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(n + 4) {
      margin-top: $space-m;
    }
  }

  &__contentLink {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:hover > div > img {
      transform: scale(1.25);
    }

    & > p {
      margin: 0;
      color: $base-text-color;
      font-family: $sans-serif;
      font-size: 1rem;
      font-weight: 600;
    }
  }

  &__productName {
    margin-bottom: $space-xxs !important;
  }

  &__imageBox {
    position: relative;
    height: 0;
    width: 100%;
    margin-bottom: $space-xs;
    padding-bottom: 100%;
    overflow: hidden;

    & > img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: 0.5s;
    }
  }
}

@include media_m {
  .template-mainNewItems {
    margin-bottom: $space-xxl-tablet;

    &__content {
      width: $three-column-tablet;
      margin-right: $space-s-tablet;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-child(n + 4) {
        margin-top: $space-m-tablet;
      }
    }

    &__contentLink {
      & > p {
        font-size: 0.9rem;
      }
    }

    &__productName {
      margin-bottom: $space-xxs-tablet !important;
    }
  }
}

@include media_s {
  .template-mainNewItems {
    &__content {
      width: $two-column-tablet-s;

      &:nth-child(3n) {
        margin-right: auto;
      }

      &:nth-child(n + 4) {
        margin-top: auto;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(n + 3) {
        margin-top: $space-m-tablet;
      }
    }
  }
}
